import React, { useContext, useState } from 'react';
import {
  Nav,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';

const LandingRightSideNavItem = () => {

  return (
    <Nav navbar className="ms-auto">
      <Link className="nav-item" to="/about">About</Link>
      <Link className="nav-item" to="/team">Team</Link>
      <Link className="nav-item" to="/solutions">Solutions</Link>
      <Link className="nav-item" to="/spearpoint">Spearpoint</Link>
      <Link className="nav-item" to="/careers">Careers</Link>
      <Link className="nav-item" to="/contact">Contact</Link>
    </Nav>
  );
};

export default LandingRightSideNavItem;
