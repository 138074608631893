import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MdAdd } from "react-icons/md";
import Dashboard from "./../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import { chart } from 'highcharts';
import axiosInstance from "./axiosInstance"
import Defender from 'helpers/defender';
import { ProgressBar } from "react-bootstrap"

function ModalDashboard({ getDashboards, selectedDashboard, showModal, closeModal, showNew }) {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    excel_file: null,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      name: '',
    });
    if (showModal === true) {
      let dashboard = selectedDashboard
      setFullscreen(true);
      setShow(true);
      setFormData({
        ...formData,
        name: getOr('', 'name', dashboard),
        excel_file_name: getOr('', 'excel_file_name', dashboard),
        excel_file: null,
      });

    }
    else {
      setFormData({
        ...formData,
        name: '',
        excel_file: null,
      });
    }
  }, [showModal]);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const ModalClose = (e) => {
    setShow(false);
    closeModal(e)
  }

  const handleSubmit = e => {
    e.preventDefault();
    let formDataDashboard = {}
    let id = window.location.href.split("/")[4]
    formDataDashboard["name"] = formData.name
    formDataDashboard["excel_file"] = formData.excel_file;
    formDataDashboard["organization_id"] = id
    const submissionData = new FormData();

    submissionData.append('dashboard[name]', formData.name);
    submissionData.append('dashboard[organization_id]', id);

    // Append the file if it exists
    if (formData.excel_file) {
      submissionData.append('dashboard[excel_file]', formData.excel_file);
    }
    if (isEmpty(selectedDashboard)) {
      axiosInstance.post("/api/v1/dashboards.json", submissionData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": Defender.token(),

        },
        onUploadProgress: data => {
          let total = Math.round((100 * data.loaded) / data.total)
          setProgress(total)
        },
      })
        .then(response => {
          if (
            (getOr("", "status", response) === 200 ||
              getOr("", "status", response) === 204) &&
            isEmpty(getOr({}, "data.errors", response))
          ) {
            setFormData({
              ...formData,
              name: '',
              excel_file: ''
            });
            toast.success(`Dashboard created`, {
              theme: 'colored'
            });
            handleClose()
            getDashboards()
          }
        })
        .catch(error => {
          toast.error(`Dashboard not created`, {
            theme: 'colored'
          });
        });
    }
    else {
      axiosInstance.patch(`/api/v1/dashboards/${getOr(0, 'id', selectedDashboard)}.json`, submissionData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": Defender.token(),

        },
        onUploadProgress: data => {
          let total = Math.round((100 * data.loaded) / data.total)
          setProgress(total)
        },
      })
        .then(response => {
          if (
            (getOr("", "status", response) === 200 ||
              getOr("", "status", response) === 204) &&
            isEmpty(getOr({}, "data.errors", response))
          ) {
            setFormData({
              ...formData,
              name: '',
              excel_file: ''
            });
            toast.success(`Dashboard Updated`, {
              theme: 'colored'
            });
            handleClose()
            getDashboards()
            setShow(false);
            closeModal(e)
          }
          else {
            toast.error(`Dashboard not updated`, {
              theme: 'colored'
            });
          }
        })
    }
  }

  const handleFieldChange = e => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      {values.map((v, idx) => (
        <>
          {showNew ?
            <Button key={idx} className="btn btn-primary" onClick={() => handleShow(v)}>
              <MdAdd key={idx + 2} />
              <span className="d-none d-sm-inline-block ms-1" key={idx + 1}>New</span>
              {typeof v === 'string' && `below ${v.split('-')[0]}`}
            </Button>
            : null}
        </>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          {isEmpty(selectedDashboard) ?
            <Modal.Title>New Ad Hoc Report</Modal.Title>
            :
            <Modal.Title>Edit Ad Hoc Report</Modal.Title>
          }
          <div className="modal-header__actions">
            <Button variant="falcon-default" onClick={ModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="modal-form max-800">
            <div className="row">
              {showNew ?
                <>
                  <Form.Group className="mb-3" controlId="DashboardName">
                    <Form.Label>Dashboard Name</Form.Label>
                    <Form.Control type="text" placeholder="" onChange={handleFieldChange} name="name" value={getOr('', 'name', formData)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="ExcelFile">
                    <Form.Label>Excel File Upload</Form.Label>
                    {/* Note: Do not set a value prop for file inputs */}
                    <Form.Control
                      type="file"
                      onChange={handleFieldChange}
                      name="excel_file"
                    />
                    {!isEmpty(getOr("", "excel_file_name", formData)) ?
                      <Form.Label>{getOr("", "excel_file_name", formData)}</Form.Label>
                      : null}
                  </Form.Group>
                  {progress > 0 ?
                    <ProgressBar now={progress} label={`${progress}%`}/>
                  : null}
                </>
                : <>
                </>
              }
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDashboard;