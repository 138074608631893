import React from 'react';
import logoMark from 'assets/img/byc-icon.png';
import { Link } from 'react-router-dom';

const Footer = ({}) => {
	return (
		<>
			<footer className="py-3">
				<div className="container">
					<div className="footer__col footer__col--nav flex-grow">
						<nav>
							<Link to="/about">About</Link>
							<Link to="/team">Team</Link>
							<Link to="/solutions">Solutions</Link>
							<Link to="/contact">Contact</Link>
						</nav>
						<div className="footer__phone">
							For a quote or support, call: <a href="tel:+19282066550">(928) 206-6550</a>
						</div>
						<div className="footer__copyright">
							&copy; {new Date().getFullYear()} Bernie Yazzie Consulting, LLC, All rights reserved.
						</div>
					</div>
					<div className="footer__col">
						<img 
							className="footer__logo"
							src={logoMark}
							alt=""
						/>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;