import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import NavbarStandard from "./front/NavbarStandard";
import Footer from "./front/Footer";
import bg3 from "assets/img/sp-images/home/bg2.png";
import { Button } from 'react-bootstrap';


const JobDetails = () => {
    const { slug } = useParams();

    const openPositions = [
        {
            role: 'Laboratory Information Systems (“LIS”) Coordinator',
            category: 'Design Role',
            location: 'In person',
            employment_type:  'Part Time- As needed',
            description: `
                The LIS Coordinator requires a broad-based knowledge of Laboratory Science computer practices as it applies to information systems application in a Laboratory Information System environment, through formal training in the Clinical Laboratory setting.<br><br>
                The LIS Coordinator will work with a team of experienced professionals to help clients transform and manage all aspects of the federal and tribal healthcare organizations’ Laboratory Package. This includes the RPMS environment, connectivity, and other components needed for successful connections. The LIS Coordinator will provide insight and direction into service delivery, execution, and technical services.<br><br>
                The LIS Coordinator is expected to:<br><br>
                <ul>
                    <li>Serve as the primary consultant and resource for client laboratory staff and supervisors as it relates to Laboratory Information System modules.</li>
                    <li>Work with clients throughout all stages of projects: identifying the major issues that the client is facing, generating hypotheses for those issues, structuring and performing analyses, identifying creative but pragmatic options against the identified problems, developing conclusions, making strategic recommendations, and developing plans to operationalize the agreed strategy.</li>
                    <li>Provide recommendations on the oversight of client laboratory computer modules and services in a cost-effective, patient-oriented manner.</li>
                    <li>Provide recommendations on all hospital-related interfaces and upgrades as they pertain to successful Laboratory services.</li>
                    <li>Provide training and education on the Laboratory Information System to applicable client staff.</li>
                    <li>Maintain an in-depth knowledge of functions, features, database structures, and data entry requirements for the laboratory module.</li>
                </ul>
            `,
            responsibilities: `
                <ul>
                    <li>Assists in the testing, implementation, and maintenance of all software updates, instrumentation interface upgrades, and replacements, new release for LIS software, and instrumentation related upgrades.</li>
                    <li>Work with other EHR information systems personnel to assist in resolving laboratory interface and testing ordering issues.</li>
                    <li>Work with other Information Systems personnel to help clients determine and provide recommendations of resolvent when system changes by other departments will affect Laboratory modules.</li>
                    <li>Provides representation on LIS matters with other departments and interfaces (Reference Laboratory) to include:
                        <ul>
                            <li>Laboratory Information Systems and Electronic Medical Record Upgrades</li>
                            <li>Interface projects with other Clinical Departments</li>
                            <li>Billing upgrades, LOINC, and test code assignment</li>
                            <li>Resolves Reference Laboratory interface errors and test code updates</li>
                        </ul>
                    </li>
                    <li>Provides guidance on quality assurance activities as they relate to LIS functions and other module functions as they affect laboratory outcomes.</li>
                    <li>Provide guidance on Laboratory reporting.</li>
                    <li>Assists clients in investigating, responding, and solving end-user questions arising from the laboratory module.</li>
                    <li>Provides guidance on all Laboratory functions, features, database structures, and data entry requirements for the laboratory module.</li>
                    <li>Assists in ensuring clients complete activities to meet regulatory initial, six-month, and yearly competencies.</li>
                    <li>Assist in the development and review of laboratory policies and procedures related to the administration and operation of the laboratory modules.</li>
                    <li>Organize and conduct meetings among departments directly impacted by the laboratory modules. Provide a forum for presenting new issues and resolving old issues related to laboratory information systems.</li>
                </ul>

            `,
            requiredQualifications: `
            <ul>
                <li>Broad-based knowledge of Laboratory computer practices through formal training with the Laboratory Information Systems.</li>
                <li>Progressive training on all Laboratory Modules.</li>
                <li>Five years of clinical laboratory experience.</li>
                <li>Three years of experience with Laboratory Information Systems.</li>
                <li>Experience handling complex transformation projects.</li>
                <li>Ability to self-manage and work with a team to achieve project objectives, including the ability to prioritize work under aggressive deadlines.</li>
                <li>Ability to work in a team and possess interpersonal skills with the ability to empathize, understand, and respect different perspectives, needs, and expectations.</li>
                <li>Ability to gather appropriate data and information.</li>
                <li>Strong critical thinking skills and ability to analyze data to make informed decisions and recommendations.</li>
                <li>Analytical skills to determine meaningful patterns, trends, and relationships in data and articulate findings for simplified business understanding.</li>
                <li>Proficient with Microsoft Suite of Applications (i.e. Excel, Word, PowerPoint, SharePoint, etc.).</li>
                <li>Excellent writing and grammar skills with the ability to write high-level and in-depth reports and summaries based on intended audience.</li>
                <li>Ability to utilize Basecamp for project management and communication of sensitive information to clients.</li>
                <li>Proficient in Microsoft Teams, Zoom, Google Meet, and other online communication platforms.</li>
                <li>Advanced to Expert level experience with RPMS-EHR and associated interfaces and applications.</li>
                <li>Knowledge of other EHR platforms and their uses.</li>
                <li>Ability to travel at our client’s request.</li>
                <li>Must be legally authorized to work in the United States without the need for employer sponsorship, now or at any time in the future.</li>
                <li>Recognizing and respecting client and patient cultural background beliefs and behaviors.</li>
            </ul>

            `,
            prefferedQualifications: `
            <ul>
                <li>Clinical Laboratory Scientist preferred.</li>
                <li>ASCP, AMT Certification or equivalent preferred.</li>
                <li>Project management skills and/or basic project management experience.</li>
                <li>Prior experience in a professional firm.</li>
                <li>Prior experience providing training.</li>
                <li>Prior experience consulting.</li>
            </ul>
            `,
            slug: 'laboratory-information-systems-lis-coordinator'
            },
            {
                role: 'Laboratory Information Systems (“LIS”) Coordinator',
                category: 'Design Role',
                location: 'In person',
                employment_type:  'Part Time- As needed',
                description: `
                The LIS Coordinator requires a broad-based knowledge of Laboratory Science computer practices as it applies to information systems application in a Laboratory Information System environment, through formal training in the Clinical Laboratory setting.<br><br>
                The LIS Coordinator will work with a team of experienced professionals to help clients transform and manage all aspects of the federal and tribal healthcare organizations’ Laboratory Package. This includes the RPMS environment, connectivity, and other components needed for successful connections. The LIS Coordinator will provide insight and direction into service delivery, execution, and technical services.<br><br>
                The LIS Coordinator is expected to:<br><br>
                <ul>
                    <li>Serve as the primary consultant and resource for client laboratory staff and supervisors as it relates to Laboratory Information System modules.</li>
                    <li>Work with clients throughout all stages of projects: identifying the major issues that the client is facing, generating hypotheses for those issues, structuring and performing analyses, identifying creative but pragmatic options against the identified problems, developing conclusions, making strategic recommendations, and developing plans to operationalize the agreed strategy.</li>
                    <li>Provide recommendations on the oversight of client laboratory computer modules and services in a cost-effective, patient-oriented manner.</li>
                    <li>Provide recommendations on all hospital-related interfaces and upgrades as they pertain to successful Laboratory services.</li>
                    <li>Provide training and education on the Laboratory Information System to applicable client staff.</li>
                    <li>Maintain an in-depth knowledge of functions, features, database structures, and data entry requirements for the laboratory module.</li>
                </ul>
            `,
            responsibilities: `
                <ul>
                    <li>Assists in the testing, implementation, and maintenance of all software updates, instrumentation interface upgrades, and replacements, new release for LIS software, and instrumentation related upgrades.</li>
                    <li>Work with other EHR information systems personnel to assist in resolving laboratory interface and testing ordering issues.</li>
                    <li>Work with other Information Systems personnel to help clients determine and provide recommendations of resolvent when system changes by other departments will affect Laboratory modules.</li>
                    <li>Provides representation on LIS matters with other departments and interfaces (Reference Laboratory) to include:
                        <ul>
                            <li>Laboratory Information Systems and Electronic Medical Record Upgrades</li>
                            <li>Interface projects with other Clinical Departments</li>
                            <li>Billing upgrades, LOINC, and test code assignment</li>
                            <li>Resolves Reference Laboratory interface errors and test code updates</li>
                        </ul>
                    </li>
                    <li>Provides guidance on quality assurance activities as they relate to LIS functions and other module functions as they affect laboratory outcomes.</li>
                    <li>Provide guidance on Laboratory reporting.</li>
                    <li>Assists clients in investigating, responding, and solving end-user questions arising from the laboratory module.</li>
                    <li>Provides guidance on all Laboratory functions, features, database structures, and data entry requirements for the laboratory module.</li>
                    <li>Assists in ensuring clients complete activities to meet regulatory initial, six-month, and yearly competencies.</li>
                    <li>Assist in the development and review of laboratory policies and procedures related to the administration and operation of the laboratory modules.</li>
                    <li>Organize and conduct meetings among departments directly impacted by the laboratory modules. Provide a forum for presenting new issues and resolving old issues related to laboratory information systems.</li>
                </ul>

            `,
            requiredQualifications: `
            <ul>
                <li>Broad-based knowledge of Laboratory computer practices through formal training with the Laboratory Information Systems.</li>
                <li>Progressive training on all Laboratory Modules.</li>
                <li>Five years of clinical laboratory experience.</li>
                <li>Three years of experience with Laboratory Information Systems.</li>
                <li>Experience handling complex transformation projects.</li>
                <li>Ability to self-manage and work with a team to achieve project objectives, including the ability to prioritize work under aggressive deadlines.</li>
                <li>Ability to work in a team and possess interpersonal skills with the ability to empathize, understand, and respect different perspectives, needs, and expectations.</li>
                <li>Ability to gather appropriate data and information.</li>
                <li>Strong critical thinking skills and ability to analyze data to make informed decisions and recommendations.</li>
                <li>Analytical skills to determine meaningful patterns, trends, and relationships in data and articulate findings for simplified business understanding.</li>
                <li>Proficient with Microsoft Suite of Applications (i.e. Excel, Word, PowerPoint, SharePoint, etc.).</li>
                <li>Excellent writing and grammar skills with the ability to write high-level and in-depth reports and summaries based on intended audience.</li>
                <li>Ability to utilize Basecamp for project management and communication of sensitive information to clients.</li>
                <li>Proficient in Microsoft Teams, Zoom, Google Meet, and other online communication platforms.</li>
                <li>Advanced to Expert level experience with RPMS-EHR and associated interfaces and applications.</li>
                <li>Knowledge of other EHR platforms and their uses.</li>
                <li>Ability to travel at our client’s request.</li>
                <li>Must be legally authorized to work in the United States without the need for employer sponsorship, now or at any time in the future.</li>
                <li>Recognizing and respecting client and patient cultural background beliefs and behaviors.</li>
            </ul>

            `,
            prefferedQualifications: `
            <ul>
                <li>Clinical Laboratory Scientist preferred.</li>
                <li>ASCP, AMT Certification or equivalent preferred.</li>
                <li>Project management skills and/or basic project management experience.</li>
                <li>Prior experience in a professional firm.</li>
                <li>Prior experience providing training.</li>
                <li>Prior experience consulting.</li>
            </ul>
            `,
            slug: 'laboratory-information-systems-lis-coordinator1'
        },
        {
            role: 'Laboratory Information Systems (“LIS”) Coordinator',
            category: 'Design Role',
            location: 'In person',
            employment_type:  'Part Time- As needed',
            description: `
                The LIS Coordinator requires a broad-based knowledge of Laboratory Science computer practices as it applies to information systems application in a Laboratory Information System environment, through formal training in the Clinical Laboratory setting.<br><br>
                The LIS Coordinator will work with a team of experienced professionals to help clients transform and manage all aspects of the federal and tribal healthcare organizations’ Laboratory Package. This includes the RPMS environment, connectivity, and other components needed for successful connections. The LIS Coordinator will provide insight and direction into service delivery, execution, and technical services.<br><br>
                The LIS Coordinator is expected to:<br><br>
                <ul>
                    <li>Serve as the primary consultant and resource for client laboratory staff and supervisors as it relates to Laboratory Information System modules.</li>
                    <li>Work with clients throughout all stages of projects: identifying the major issues that the client is facing, generating hypotheses for those issues, structuring and performing analyses, identifying creative but pragmatic options against the identified problems, developing conclusions, making strategic recommendations, and developing plans to operationalize the agreed strategy.</li>
                    <li>Provide recommendations on the oversight of client laboratory computer modules and services in a cost-effective, patient-oriented manner.</li>
                    <li>Provide recommendations on all hospital-related interfaces and upgrades as they pertain to successful Laboratory services.</li>
                    <li>Provide training and education on the Laboratory Information System to applicable client staff.</li>
                    <li>Maintain an in-depth knowledge of functions, features, database structures, and data entry requirements for the laboratory module.</li>
                </ul>
            `,
            responsibilities: `
                <ul>
                    <li>Assists in the testing, implementation, and maintenance of all software updates, instrumentation interface upgrades, and replacements, new release for LIS software, and instrumentation related upgrades.</li>
                    <li>Work with other EHR information systems personnel to assist in resolving laboratory interface and testing ordering issues.</li>
                    <li>Work with other Information Systems personnel to help clients determine and provide recommendations of resolvent when system changes by other departments will affect Laboratory modules.</li>
                    <li>Provides representation on LIS matters with other departments and interfaces (Reference Laboratory) to include:
                        <ul>
                            <li>Laboratory Information Systems and Electronic Medical Record Upgrades</li>
                            <li>Interface projects with other Clinical Departments</li>
                            <li>Billing upgrades, LOINC, and test code assignment</li>
                            <li>Resolves Reference Laboratory interface errors and test code updates</li>
                        </ul>
                    </li>
                    <li>Provides guidance on quality assurance activities as they relate to LIS functions and other module functions as they affect laboratory outcomes.</li>
                    <li>Provide guidance on Laboratory reporting.</li>
                    <li>Assists clients in investigating, responding, and solving end-user questions arising from the laboratory module.</li>
                    <li>Provides guidance on all Laboratory functions, features, database structures, and data entry requirements for the laboratory module.</li>
                    <li>Assists in ensuring clients complete activities to meet regulatory initial, six-month, and yearly competencies.</li>
                    <li>Assist in the development and review of laboratory policies and procedures related to the administration and operation of the laboratory modules.</li>
                    <li>Organize and conduct meetings among departments directly impacted by the laboratory modules. Provide a forum for presenting new issues and resolving old issues related to laboratory information systems.</li>
                </ul>

            `,
            requiredQualifications: `
            <ul>
                <li>Broad-based knowledge of Laboratory computer practices through formal training with the Laboratory Information Systems.</li>
                <li>Progressive training on all Laboratory Modules.</li>
                <li>Five years of clinical laboratory experience.</li>
                <li>Three years of experience with Laboratory Information Systems.</li>
                <li>Experience handling complex transformation projects.</li>
                <li>Ability to self-manage and work with a team to achieve project objectives, including the ability to prioritize work under aggressive deadlines.</li>
                <li>Ability to work in a team and possess interpersonal skills with the ability to empathize, understand, and respect different perspectives, needs, and expectations.</li>
                <li>Ability to gather appropriate data and information.</li>
                <li>Strong critical thinking skills and ability to analyze data to make informed decisions and recommendations.</li>
                <li>Analytical skills to determine meaningful patterns, trends, and relationships in data and articulate findings for simplified business understanding.</li>
                <li>Proficient with Microsoft Suite of Applications (i.e. Excel, Word, PowerPoint, SharePoint, etc.).</li>
                <li>Excellent writing and grammar skills with the ability to write high-level and in-depth reports and summaries based on intended audience.</li>
                <li>Ability to utilize Basecamp for project management and communication of sensitive information to clients.</li>
                <li>Proficient in Microsoft Teams, Zoom, Google Meet, and other online communication platforms.</li>
                <li>Advanced to Expert level experience with RPMS-EHR and associated interfaces and applications.</li>
                <li>Knowledge of other EHR platforms and their uses.</li>
                <li>Ability to travel at our client’s request.</li>
                <li>Must be legally authorized to work in the United States without the need for employer sponsorship, now or at any time in the future.</li>
                <li>Recognizing and respecting client and patient cultural background beliefs and behaviors.</li>
            </ul>

            `,
            prefferedQualifications: `
            <ul>
                <li>Clinical Laboratory Scientist preferred.</li>
                <li>ASCP, AMT Certification or equivalent preferred.</li>
                <li>Project management skills and/or basic project management experience.</li>
                <li>Prior experience in a professional firm.</li>
                <li>Prior experience providing training.</li>
                <li>Prior experience consulting.</li>
            </ul>
            `,
            slug: 'laboratory-information-systems-lis-coordinator2'
        },
        {
            role: 'Laboratory Information Systems (“LIS”) Coordinator',
            category: 'Design Role',
            location: 'In person',
            employment_type:  'Part Time- As needed',
            description: `
                The LIS Coordinator requires a broad-based knowledge of Laboratory Science computer practices as it applies to information systems application in a Laboratory Information System environment, through formal training in the Clinical Laboratory setting.<br><br>
                The LIS Coordinator will work with a team of experienced professionals to help clients transform and manage all aspects of the federal and tribal healthcare organizations’ Laboratory Package. This includes the RPMS environment, connectivity, and other components needed for successful connections. The LIS Coordinator will provide insight and direction into service delivery, execution, and technical services.<br><br>
                The LIS Coordinator is expected to:<br><br>
                <ul>
                    <li>Serve as the primary consultant and resource for client laboratory staff and supervisors as it relates to Laboratory Information System modules.</li>
                    <li>Work with clients throughout all stages of projects: identifying the major issues that the client is facing, generating hypotheses for those issues, structuring and performing analyses, identifying creative but pragmatic options against the identified problems, developing conclusions, making strategic recommendations, and developing plans to operationalize the agreed strategy.</li>
                    <li>Provide recommendations on the oversight of client laboratory computer modules and services in a cost-effective, patient-oriented manner.</li>
                    <li>Provide recommendations on all hospital-related interfaces and upgrades as they pertain to successful Laboratory services.</li>
                    <li>Provide training and education on the Laboratory Information System to applicable client staff.</li>
                    <li>Maintain an in-depth knowledge of functions, features, database structures, and data entry requirements for the laboratory module.</li>
                </ul>
            `,
            responsibilities: `
                <ul>
                    <li>Assists in the testing, implementation, and maintenance of all software updates, instrumentation interface upgrades, and replacements, new release for LIS software, and instrumentation related upgrades.</li>
                    <li>Work with other EHR information systems personnel to assist in resolving laboratory interface and testing ordering issues.</li>
                    <li>Work with other Information Systems personnel to help clients determine and provide recommendations of resolvent when system changes by other departments will affect Laboratory modules.</li>
                    <li>Provides representation on LIS matters with other departments and interfaces (Reference Laboratory) to include:
                        <ul>
                            <li>Laboratory Information Systems and Electronic Medical Record Upgrades</li>
                            <li>Interface projects with other Clinical Departments</li>
                            <li>Billing upgrades, LOINC, and test code assignment</li>
                            <li>Resolves Reference Laboratory interface errors and test code updates</li>
                        </ul>
                    </li>
                    <li>Provides guidance on quality assurance activities as they relate to LIS functions and other module functions as they affect laboratory outcomes.</li>
                    <li>Provide guidance on Laboratory reporting.</li>
                    <li>Assists clients in investigating, responding, and solving end-user questions arising from the laboratory module.</li>
                    <li>Provides guidance on all Laboratory functions, features, database structures, and data entry requirements for the laboratory module.</li>
                    <li>Assists in ensuring clients complete activities to meet regulatory initial, six-month, and yearly competencies.</li>
                    <li>Assist in the development and review of laboratory policies and procedures related to the administration and operation of the laboratory modules.</li>
                    <li>Organize and conduct meetings among departments directly impacted by the laboratory modules. Provide a forum for presenting new issues and resolving old issues related to laboratory information systems.</li>
                </ul>

            `,
            requiredQualifications: `
            <ul>
                <li>Broad-based knowledge of Laboratory computer practices through formal training with the Laboratory Information Systems.</li>
                <li>Progressive training on all Laboratory Modules.</li>
                <li>Five years of clinical laboratory experience.</li>
                <li>Three years of experience with Laboratory Information Systems.</li>
                <li>Experience handling complex transformation projects.</li>
                <li>Ability to self-manage and work with a team to achieve project objectives, including the ability to prioritize work under aggressive deadlines.</li>
                <li>Ability to work in a team and possess interpersonal skills with the ability to empathize, understand, and respect different perspectives, needs, and expectations.</li>
                <li>Ability to gather appropriate data and information.</li>
                <li>Strong critical thinking skills and ability to analyze data to make informed decisions and recommendations.</li>
                <li>Analytical skills to determine meaningful patterns, trends, and relationships in data and articulate findings for simplified business understanding.</li>
                <li>Proficient with Microsoft Suite of Applications (i.e. Excel, Word, PowerPoint, SharePoint, etc.).</li>
                <li>Excellent writing and grammar skills with the ability to write high-level and in-depth reports and summaries based on intended audience.</li>
                <li>Ability to utilize Basecamp for project management and communication of sensitive information to clients.</li>
                <li>Proficient in Microsoft Teams, Zoom, Google Meet, and other online communication platforms.</li>
                <li>Advanced to Expert level experience with RPMS-EHR and associated interfaces and applications.</li>
                <li>Knowledge of other EHR platforms and their uses.</li>
                <li>Ability to travel at our client’s request.</li>
                <li>Must be legally authorized to work in the United States without the need for employer sponsorship, now or at any time in the future.</li>
                <li>Recognizing and respecting client and patient cultural background beliefs and behaviors.</li>
            </ul>

            `,
            prefferedQualifications: `
            <ul>
                <li>Clinical Laboratory Scientist preferred.</li>
                <li>ASCP, AMT Certification or equivalent preferred.</li>
                <li>Project management skills and/or basic project management experience.</li>
                <li>Prior experience in a professional firm.</li>
                <li>Prior experience providing training.</li>
                <li>Prior experience consulting.</li>
            </ul>
            `,
            slug: 'laboratory-information-systems-lis-coordinator3'
        }
      ] 

    const job = openPositions.find(position => position.slug === slug);
    const [submitted, setSubmitted] = useState(false);

    if (!job) {
        return <h2>Position not found</h2>;
    }

    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      resume: "",
      message: "",
    });
  
    // Handle input change
    const handleFieldChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    // Handle file upload
    const handleFileChange = (e) => {
      const uploadedFile = e.target.files[0];
      if (uploadedFile) {
        setFile({
          file: uploadedFile, // Store actual file object
          name: uploadedFile.name,
          size: uploadedFile.size,
          type: uploadedFile.type,
        });
      }
    };
  
    // Validation function
    const validateForm = () => {
      let newErrors = {};
      if (!formData.firstName.trim()) newErrors.firstName = "First name is required.";
      if (!formData.lastName.trim()) newErrors.lastName = "Last name is required.";
      if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/))
        newErrors.email = "Invalid email format.";
      if (!formData.phone.match(/^\d{10}$/))
        newErrors.phone = "Phone number must be 10 digits.";
      if (!file) newErrors.resume = "Resume is required.";
      if (!formData.message.trim()) newErrors.message = "This field is required.";
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
        setValidated(true);
        console.log("Form submitted successfully!", formData, file);
        setSubmitted(true);
        // Add API submission logic here
      } else {
        setValidated(false);
      }
    };

    let pageWidth = window.innerWidth;

    useEffect(() => {
      pageWidth = window.innerWidth
  
    }, [window.innerWidth])


  return (
    
    <div className="job-details">
      <NavbarStandard />

      <div className="content content--front">
        <section className="py-0">
          <div
            className="heading bar3"
            style={{ backgroundImage: `url(${bg3})` }}
          >
            <div className="container">
              <h1 className="page-title page-title--job-details">{job.role}</h1>
              <div className="subtitle">
                {job.location} | {job.employment_type}
              </div>
            </div>
          </div>

          <div className="container">
          {
            ['Description', 'Responsibilities', 'Required Qualifications', 'Preffered Qualifications'].map((section, index) => (
              pageWidth > 991 ? (
                <div key={index} className="job-details__container">
                <h3 className="job-details__container__section-title">{section}:</h3>
                <div
                  className="job-details__container__section-details"
                  dangerouslySetInnerHTML={{
                    __html: job[
                      section
                        .replace(/\s(.)/g, (match, group1) => group1.toUpperCase()) // Capitalizes first letter after space
                        .replace(/\s/g, '') // Removes spaces
                        .replace(/^([A-Z])/, (match) => match.toLowerCase()) // Converts first letter to lowercase
                    ] || '',
                  }}
                />
              </div>
              ) : (
               section == 'Description' ? (
                <div key={index} className="job-details__container">
                  <h3 className="job-details__container__section-title">{section}:</h3>
                  <div
                    className="job-details__container__section-details"
                    dangerouslySetInnerHTML={{
                      __html: job[
                        section
                          .replace(/\s(.)/g, (match, group1) => group1.toUpperCase()) // Capitalizes first letter after space
                          .replace(/\s/g, '') // Removes spaces
                          .replace(/^([A-Z])/, (match) => match.toLowerCase()) // Converts first letter to lowercase
                      ] || '',
                    }}
                  />
                </div>  
               ) : (
                <details
                    key={index}
                    className="job-details__container"
                    open={section === 'Description'} // Always open for Description
                  >
                    <summary>
                      <h3 className="job-details__container__section-title">{section}:</h3>
                    </summary>
                    <div
                      className="job-details__container__section-details"
                      dangerouslySetInnerHTML={{
                        __html: job[
                          section
                            .replace(/\s(.)/g, (match, group1) => group1.toUpperCase()) // Capitalize letters after spaces
                            .replace(/\s/g, '') // Remove spaces
                            .replace(/^([A-Z])/, (match) => match.toLowerCase()) // Lowercase first letter
                        ] || '',
                      }}
                    />
                  </details>
               )
              )
            ))
          }


						<div className="container max-800 container--job-form">
                <h2>Apply Now</h2>

                {submitted && (
                  <div className="alert alert-success" role="alert">
                    Your application has been submitted successfully!
                  </div>
                )}

                <Form className="job-form" noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="row">
                    <div className="col-sm-6 mb-4">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleFieldChange}
                        isInvalid={!!errors.firstName}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </div>
                    <div className="col-sm-6 mb-4">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleFieldChange}
                        isInvalid={!!errors.lastName}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group className="row">
                    <div className="col-sm-6 mb-4">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleFieldChange}
                        isInvalid={!!errors.email}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </div>
                    <div className="col-sm-6 mb-4">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleFieldChange}
                        isInvalid={!!errors.phone}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  {/* File Upload */}
                  <Form.Group className="mb-3">
                    <Form.Control type="file" id="fileUpload" accept=".pdf, .docx, .doc, .rtf, .txt" style={{ display: "none" }} onChange={handleFileChange} />
                    <label htmlFor="fileUpload" className="d-flex align-items-center gap-2 upload-resume__label">
                      {!file && (
                        <div className="d-flex align-items-center gap-2 mt-2">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.875 13.5V4.33125L4.95 7.25625L3.375 5.625L9 0L14.625 5.625L13.05 7.25625L10.125 4.33125V13.5H7.875ZM2.25 18C1.63125 18 1.10175 17.7799 0.6615 17.3396C0.22125 16.8994 0.00075 16.3695 0 15.75V12.375H2.25V15.75H15.75V12.375H18V15.75C18 16.3687 17.7799 16.8986 17.3396 17.3396C16.8994 17.7806 16.3695 18.0007 15.75 18H2.25Z" fill="#114546" />
                          </svg>
                          <span>Upload Resume</span>
                        </div>
                      )}
                      {file && (
                        <div className="d-flex align-items-center gap-2 mt-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m8 18v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1zm-4-8a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2" />
                          </svg>
                          <span>{file.name}</span>
                        </div>
                      )}
                    </label>
                    {errors.resume && <div className="text-danger">{errors.resume}</div>}
                  </Form.Group>

                  {/* Message Field */}
                  <Form.Group className="mb-5">
                    <Form.Label>How did you hear about us?</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      value={formData.message}
                      onChange={handleFieldChange}
                      isInvalid={!!errors.message}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                  </Form.Group>

                  <Button className="btn--rg" variant="primary" type="submit">
                    Apply
                  </Button>
                </Form>
            </div>


          </div>
        </section>
      </div>

      <Footer />
    </div>
  )
}

export default JobDetails