import React from "react";
import NavbarStandard from "./front/NavbarStandard";
import Footer from "./front/Footer";

// Images
import bg3 from "assets/img/sp-images/home/bg2.png";
import { useNavigate } from "react-router-dom";

const Careers = ({}) => {

    const Navigate = useNavigate();
  

  const openPositions = [
    {
        role: 'Laboratory Information Systems (“LIS”) Coordinator',
        location: 'In person',
        employment_type:  'Part Time- As needed',
        slug: 'laboratory-information-systems-lis-coordinator'
    }
  ] 


  return (
    <>
      <NavbarStandard />

      <div className="content content--front">
        <section className="py-0">
          <div
            className="heading bar3"
            style={{ backgroundImage: `url(${bg3})` }}
          >
            <div className="container">
              <div className="heading__overline">
                JOIN THE BERNIE YAZZIE CONSULTING TEAM
              </div>
              <h1 className="page-title">Career Opportunity&apos;s</h1>
              <div className="subtitle">
                We are always looking for talented individuals to join our team.
              </div>
            </div>
          </div>

          <div className="container">
            <div className="carrers">
            {
                openPositions.map((position, index) => (
                    <div className="carrers__position" key={index} onClick={() => Navigate(`/careers/${position.slug}`)}>
                    <div className="carrers__position__header">
                    <h2 className="carrers__position__title">{position.role}</h2>

                        <a href="#" onClick={() => Navigate(`/careers/${position.slug}`)}>
                            <span>View Job</span>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.36399 12.0899L10.9695 3.7705V11.4131L12.9706 11.4063V0.468726H1.65688V2.39647L9.55526 2.4033L0.949772 10.7227L2.36399 12.0899Z" fill="#2a8b8c"/>
                            </svg>

                        </a>
                    </div>

                    <div className="carrers__position__footer">
                        <div className="carrers__position__type">
                        <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 0C5.87827 0 3.84344 0.848122 2.34315 2.35779C0.842855 3.86746 0 5.91501 0 8.05C0 14.0875 8 23 8 23V10.925C7.24224 10.925 6.51551 10.6221 5.9797 10.0829C5.44388 9.54376 5.14286 8.8125 5.14286 8.05C5.14286 7.2875 5.44388 6.55623 5.9797 6.01707C6.51551 5.4779 7.24224 5.175 8 5.175V0Z" fill="#248A91"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 23C8 23 16 14.0875 16 8.05C16 5.91501 15.1571 3.86746 13.6569 2.35779C12.1566 0.848122 10.1217 0 8 0V5.175C8.75776 5.175 9.48449 5.4779 10.0203 6.01707C10.5561 6.55623 10.8571 7.2875 10.8571 8.05C10.8571 8.42755 10.7832 8.8014 10.6397 9.15022C10.4961 9.49903 10.2856 9.81596 10.0203 10.0829C9.755 10.3499 9.44003 10.5617 9.09338 10.7062C8.74674 10.8506 8.37521 10.925 8 10.925V23Z" fill="#114546"/>
                        </svg>
                            {position.employment_type}
                        </div>
                        <div className="carrers__position__location">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20V10.75L9.25 10V4H10V0Z" fill="#248A91"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0V4H10.75V9.38L14.553 13.183L13.493 14.243L10 10.75V20Z" fill="#114546"/>
                        </svg>

                            {position.location}
                        </div>
                    </div>
                </div>
                ))
            }
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Careers;